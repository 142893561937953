.about {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  gap: 120px;
  align-items: center;
  justify-content: center;
}

.about-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 604px;
  width: 100%;
}

@media (max-width: 1000px) {
  .about {
    padding-top: 60px;
    padding-bottom: 60px;
    gap: 40px;
  }
}
@media (max-width: 786px) {
  .about {
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .about-text{
    align-items: center;
  }
}
