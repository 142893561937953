.static-content {
  padding-top: 40px;
  padding-bottom: 90px;
  background: var(--Brand-Purple-Light, #ece8ff);
  width: 100%;
}

.static-content-title {
  text-align: center;
  margin-bottom: 33px;
  margin-top: 33px;
}

.static-content-text-heading {
  margin-top: 33px;
  color: #000;
}

.static-content-text-desc {
  margin-top: 33px;
  color: #000;
}

.static-content-logo img{
  height: 70px;
}
