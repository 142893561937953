@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../public/AvenirNextCyr-Regular.ttf") format("truetype");
  /* Additional font properties if needed */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Avenir Next Cyr";
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 9px;
  border-radius: 9px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 9px;
  border-radius: 9px;
  background: #6d6d6d;
}

.common-heading {
  color: var(--Black, #000);

  /* Heading */
  font-family: "Avenir Next Cyr";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.common-paragraph {
  color: var(--Grey-600, #666);
  /* Paragraph */
  font-family: "Avenir Next Cyr";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.common-main-heading {
  color: #2f2f2f;

  /* Main Heading */
  font-family: "Avenir Next Cyr";
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 62px */
  text-transform: capitalize;
}

.common-paragraph2 {
  color: var(--Grey-300, #999);

  /* Paragraph  2 */
  font-family: "Avenir Next Cyr";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.common-paragraph4 {
  color: var(--Grey-300, #999);
  text-align: left;

  /* Paragraph 4 */
  font-family: "Avenir Next Cyr";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.common-card-heading {
  color: var(--Black, #000);

  /* Card Heading */
  font-family: "Avenir Next Cyr";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.common-header-tag {
  color: var(--Brand-Purple-Dark, #433896);

  /* Header Tag */
  font-family: "Avenir Next Cyr";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-variant: all-small-caps;
}

.horizontal-line {
  height: 1px;
  align-self: stretch;
  text-align: center;
  background: #7f7f7f;
}

.active {
  color: var(--Brand-Purple-Dark, #433896);
  font-weight: 700;
}

.li {
  cursor: pointer;
}

.container {
  padding: 0px 100px;
}

.btn {
  padding: 10px 20px;
  color: white;
  border-radius: 4px;
  background: var(--Brand-Purple-Dark, #433896);
  border: 1px solid #433896;
  cursor: pointer;
  width: fit-content;
}

.download-app-links {
  display: flex;
  gap: 20px;
  float: left;
}

.download-app-links img {
  width: 100%;
  object-fit: scale-down;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .container {
    padding: 0px 5%;
  }
  .common-main-heading {
    font-size: 36px;
    font-weight: 700;
  }

  .common-paragraph {
    font-size: 16px;
  }

  .common-heading {
    font-size: 30px;
  }
}

@media (max-width: 1000px) {
  .download-app-links {
    align-self: center;
  }
}

@media (max-width: 786px) {
  .common-main-heading {
    font-size: 34px;
  }
  .common-heading {
    font-size: 26px;
  }
  .download-app-links {
    justify-content: center;
    /* width: 100%; */
  }
}
@media (max-width: 480px) {
  .container {
    padding: 20px;
  }
  .common-main-heading {
    font-size: 30px;
    line-height: 36px;
  }
  .common-paragraph {
    font-size: 14px;
  }

  /* .common-paragraph2{
    font-size: 14px;
  } */
}
