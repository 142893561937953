.hero {
  min-height: 70vh;
  background: var(--Brand-Purple-Light, #ece8ff);
}

.hero-section {
  padding: 190px 0px 70px 0px;
  gap: 80px;
  display: flex;
  align-items: center;
}

.hero-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hero-text-desc {
  margin-top: 20px;
}

.hero-text > .download-app-links {
  margin-top: 30px;
}

.hero-img{
  align-self: center;
}
.hero-img-mobile {
  display: none;
}

@media (max-width: 1000px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
    gap: 40px;
  }
  .hero-img-desktop {
    display: none;
  }
  .hero-img-mobile {
    display: block;
  }
}
@media (max-width: 600px) {
  .hero-img img {
    width: 100%;
  }
}
@media (max-width: 550px) {
  .hero-section {
    padding-top: 110px;
    padding-bottom: 20px;
  }
}
