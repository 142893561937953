.testimonials {
  background: var(--Brand-Purple-Light, #ece8ff);
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}
.testimonials-heading {
  text-align: center;
  margin-top: 19px;
}

.testimonials-tag {
  text-align: -webkit-center;
}
.testimonials-card-about {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.testimonials-card-desc {
  margin-top: 20px;
}
.carousel-testimonial-item {
  text-align: -webkit-center;
  height: 268px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.author-name {
  color: var(--Black, #000);
}
.author-pic img{
  width: 50px;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .testimonials {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}
