/* Carousel */
.carousel-container {
  /* margin-left: -100px; */
  /* margin-right: -100px; */
}

.carousel-item {
  width: 367px;
  height: 324px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 30px;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 8px 15px 0px rgba(59, 50, 122, 0.1);
}

.carousel-item img {
  max-width: 100%;
  height: auto;
}

.slick-track {
  display: flex !important;
  gap: 20px;
}

.custom-arrow {
  position: absolute !important;
  top: 100% !important;
  margin-top: 12px;
  z-index: 2;
}
.custom-prev-arrow {
  right: 63%;
}
.custom-next-arrow {
  left: 63%;
}

.slick-dots {
  bottom: unset !important;
}

.slick-track {
  margin-top: 45px;
  margin-bottom: 50px;
}

.tag {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Brand-Color, #fcff03);
  width: fit-content;
}

.scrollToTopBtn {
  background-color: #433896;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 30px;
  line-height: 48px;
  width: 48px;
  /* place it at the bottom right corner */
  position: fixed;
  bottom: 30px;
  right: 30px;
  /* keep it on top of everything else */
  z-index: 100;
  /* hide with opacity */
  opacity: 0;
  /* also add a translate effect */
  transform: translateY(100px);
  /* and a transition */
  transition: all 0.5s ease;
}

.showBtn {
  opacity: 1;
  transform: translateY(0);
}

.app-banner{
  margin-top: 40px;
  margin-bottom: 40px;
}

.download-app {
  background: #FFF;
  text-align: left;
  padding-top:60px ;
  padding-bottom:60px ;
}

.download-app-heading {
  color: white;
  margin-top: 14px;
}

.download-app-Bg {
  position: relative;
}

.download-app img {
  width: 100%;
}

.download-app-text {
  position: absolute;
  top: 0;
  height: 100%;
  align-content: center;
  max-width: 60%;
  padding-left: 5%;
}

.download-app-text > .download-app-links {
  margin-top: 40px;
}

@media (max-width: 786px) {
  .download-app-Bg > img {
    display: none;
  }
  .download-app-text {
    position: relative;
    padding-left: unset;
  }
  .download-app {
    background-color: #433896;
    padding: 30px;
    text-align: -webkit-center;
    border-radius: 30px;
  }

  .download-app-text > .download-app-links {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .download-app-text {
    max-width: 100%;
  }
}
