.nav-items {
  /* margin: 0px 100px; */
  display: flex;
  /* height: 72px; */
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #fff;
  backdrop-filter: blur(5px);
}

nav {
  position: fixed;
  width: 100%;
  top: 40px;
  z-index: 5;
}

.logo {
  height: 32px;
  cursor: pointer;
}

.nav-menu-desktop {
  display: flex;
  gap: 35px;
  align-items: center;
}

.on-scroll-nav {
  top: 0px;
  padding: 0px;
  transition: top 0.3s ease, padding 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 1px 10px #999;
}

.on-scroll-nav .nav-items {
  border-radius: 0px;
}

.hamburger-menu {
  cursor: pointer;
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #433896;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

.nav-menu-mobile {
  display: none;
}

/* @media (max-width: 786px) {
  .nav-items {
    flex-direction: column;
    gap: 15px;
  }
} */
@media (max-width: 900px) {
  nav .ul {
    gap: 30px;
  }
  nav {
    padding: 0 !important;
    top: 0px;
  }

  .hamburger-menu {
    display: inline-block;
  }

  .nav-menu-desktop {
    display: none;
  }

  .nav-menu-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 40px;
    background-color: white;
    box-shadow: 0px 1px 10px #999;
  }

  .nav-items {
    flex-direction: row;
    border-radius: unset;
  }
}
