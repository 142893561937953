.features {
  background: var(--Brand-Purple-Light, #ece8ff);
  padding-top: 80px;
  padding-bottom: 80px;
}
.features-heading {
  text-align: center;
  margin-top: 19px;
}

.features-card-heading {
  margin-top: 20px;
}
.features-card-desc {
  margin-top: 10px;
}

.features-tag {
  text-align: -webkit-center;
}

@media (max-width: 768px) {
  .features {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}