.contact-us {
  background: #ece8ff;
  padding-top: 80px;
  padding-bottom: 80px;
}

.contact-us-tag {
  text-align: -webkit-center;
}

.contact-us-heading {
  margin-top: 14px;
  text-align: center;
}

/* .form-title {
  margin-top: 20px;
}
.form-tagline {
  margin-top: 10px;
  color: #666;
} */

.contact-form {
  margin-top: 60px;
  padding: 40px;
  gap: 40px;
  border-radius: 20px;
  background: #fff;
}

.form-group {
  margin-bottom: 20px;
  color: black;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.form-row div {
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  /* max-width: 500px; */
  border-radius: 4px;
  border: 1px solid rgba(91, 81, 176, 0.4);
  padding: 8px 12px;
  background: transparent;
}

.form-group-checkbox input {
  width: 35px;
}

.form-group textarea {
  height: 80px;
  resize: none;
}

.contact-form > button {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .contact-us {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .contact-form {
    margin-top: 40px;
  }
  .form-row {
    flex-direction: column;
    gap: 0px;
  }
}
