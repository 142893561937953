.technology {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  gap: 80px;
  align-items: center;
}
.technology-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1000px) {
  .technology {
    padding-top: 60px;
    padding-bottom: 60px;
    gap: 40px;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  .technology-text {
    align-items: center;
  }
}

@media (max-width: 1000px) {
  .about {
    padding-top: 60px;
    padding-bottom: 60px;
    gap: 40px;
  }
}
@media (max-width: 786px) {
  .technology {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .technology-img img {
    width: 100%;
  }
}
