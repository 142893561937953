.footer {
  text-align: -webkit-center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer-text {
  /* padding-top: 60px;
  padding-bottom: 60px; */
  text-align: center;
  display: flex;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.footer-text > .horizontal-line {
  background: rgba(0, 0, 0, 0.12);
}

.footer-nav-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  color: black;
}

.footer-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.footer-end-links {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.footer-logo img {
  height: 70px;
}

@media (max-width: 1000px) {
  .download-app-text > .download-app-links {
    margin-top: 20px;
  }
}

@media (max-width: 786px) {
  .footer {
    padding-top: 40px;
  }

  .footer-end {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
}

@media (max-width: 480px) {
  .footer-nav-links {
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }
}
